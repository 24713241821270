// React
import React from 'react';

// Gatsby
import { graphql } from 'gatsby';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Packages
import { StickyContainer } from 'react-sticky';

// Utilities
import { useTheme } from 'hooks';

// Components
import SEO from 'components/seo';
import ProductHero from 'components/_product/productHero';
import ProductDescription from 'components/_product/productDescription';
import Subscription from 'components/subscription';
import Newsletter from 'components/newsletter';

const ProductBundleComponent = props => {
    const {
        location,
        pageContext,
        pageContext: { locale },
        data: {
            datoCmsBundle,
            datoCmsBundle: {
                seoMetaTags,
                showSubscription = false,
                showNewsletter = false,
                sku,
                price,
            },
            datoCmsPageShop,
        },
    } = props;

    // Set theme
    const theme = useTheme(s.color('white'));

    return (
        <>
            <s.layout.PageContainer>
                <SEO {...{ seoMetaTags, locale }} />
                <StickyContainer>
                    <ContentWrapper>
                        <ProductHero
                            {...{
                                parent: datoCmsPageShop,
                                parentTheme: datoCmsPageShop.theme,
                                theme,
                                product: datoCmsBundle,
                                productVariations: [
                                    {
                                        sku,
                                        price,
                                    },
                                ],
                                bundle: true,
                                location,
                                pageContext,
                            }}
                        />
                        <ProductDescription
                            {...{
                                theme,
                                product: datoCmsBundle,
                                pageContext,
                            }}
                        />
                        {showNewsletter && (
                            <Newsletter
                                {...{
                                    pageContext,
                                    location,
                                }}
                            />
                        )}
                        {showSubscription && (
                            <Subscription
                                {...{
                                    pageContext,
                                    location,
                                }}
                            />
                        )}
                    </ContentWrapper>
                </StickyContainer>
            </s.layout.PageContainer>
        </>
    );
};

export default ProductBundleComponent;

export const productBundleQuery = graphql`
    query ProductBundleQuery($itemId: String!) {
        datoCmsBundle(id: { eq: $itemId }) {
            ...ProductBundleFragment
        }
        datoCmsPageShop {
            theme {
                ...Theme
            }
            slug
        }
    }
`;

const ContentWrapper = styled.div`
    position: relative;
    z-index: ${s.layout.zIndex.content};
`;
